import { useState } from "react";

const url = process.env.REACT_APP_URL;

const Image = () => {
  const [hero, setHero] = useState(`${url}/hero.svg`);
  const [backg, setBackg] = useState(`${url}/background.png`);
  const [project, setProject] = useState(`${url}/project.png`);
  const [conslusion, setConclusion] = useState(`${url}/conclusion.png`);
  const [savedHero, setSavedHero] = useState(null);
  const [savedBg, setSavedBg] = useState(null);
  const [savedProject, setSavedProject] = useState(null);
  const [savedConclusion, setSavedConclusion] = useState(null);

  const renderUpload = (change, saved) => (e) => {
    let uploaded = e.target.files[0];
    change(URL.createObjectURL(uploaded));
    saved(uploaded);
  };

  const handleUpload = async (save, params) => {
    if (!save) {
      alert("upload image first");
    } else {
      let formData = new FormData();
      formData.append("photo", save, params);

      const response = await fetch(`${url}/api/image/`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      alert(JSON.stringify(data));
    }
  };

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div className="bg-transparent min-h-fit p-5 min-w-full">
        <h1 className="w-full text-center mt-10 text-2xl font-bold">
          Home Image
        </h1>
        <img src={hero} className="w-full mx-auto bg-transparent" />
      </div>
      <p className="text-center py-4 text-xl text-red-500">
        Image extension must *.svg
      </p>
      <div className="flex justify-center item-center gap-2">
        <input
          type="file"
          accept=".png"
          onChange={renderUpload(setHero, setSavedHero)}
          className="block cursor-pointer file:cursor-pointer w-full text-sm text-slate-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-violet-50 file:text-violet-700
          hover:file:bg-violet-100"
          aria-label="Upload"
        ></input>
        <button
          onClick={() => handleUpload(savedHero, "roadmap.png")}
          className="text-white hover:scale-95 w-1/2 bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800"
        >
          Upload
        </button>
      </div>
      <div className="bg-transparent min-h-fit p-6 min-w-full">
        <h1 className="w-full text-center mt-10 mb-5 text-2xl font-bold">
          Background Image
        </h1>
        <img src={backg} className="w-full mx-auto bg-transparent" />
      </div>
      <p className="text-center py-4 text-xl text-red-500">
        Image extension must *.png
      </p>
      <div className="flex justify-center item-center my-10 gap-2">
        <input
          type="file"
          accept="image/png"
          onChange={renderUpload(setBackg, setSavedBg)}
          className="block cursor-pointer file:cursor-pointer w-full text-sm text-slate-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-violet-50 file:text-violet-700
          hover:file:bg-violet-100"
          aria-label="Upload"
        ></input>
        <button
          onClick={() => handleUpload(savedBg, "background.png")}
          className="text-white hover:scale-95 w-1/2 bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800"
        >
          Upload
        </button>
      </div>
      <div className="bg-transparent min-h-fit p-6 min-w-full">
        <h1 className="w-full text-center mt-10 mb-5 text-2xl font-bold">
          Project Image
        </h1>
        <img src={project} className="w-full mx-auto bg-transparent" />
      </div>
      <p className="text-center py-4 text-xl text-red-500">
        Image extension must *.png
      </p>
      <div className="flex justify-center my-10 item-center gap-2">
        <input
          type="file"
          accept="image/png"
          onChange={renderUpload(setProject, setSavedProject)}
          className="block cursor-pointer file:cursor-pointer w-full text-sm text-slate-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-violet-50 file:text-violet-700
          hover:file:bg-violet-100"
          aria-label="Upload"
        ></input>
        <button
          onClick={() => handleUpload(savedProject, "project.png")}
          className="text-white hover:scale-95 w-1/2 bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800"
        >
          Upload
        </button>
      </div>
      <div className="bg-transparent min-h-fit p-6 min-w-full">
        <h1 className="w-full text-center mt-10 mb-5 text-2xl font-bold">
          Conclusion Image
        </h1>
        <img src={conslusion} className="w-full mx-auto bg-transparent" />
      </div>
      <p className="text-center py-4 text-xl text-red-500">
        Image extension must *.png
      </p>
      <div className="flex justify-center my-10 item-center gap-2">
        <input
          type="file"
          accept="image/png"
          onChange={renderUpload(setConclusion, setSavedConclusion)}
          className="block cursor-pointer file:cursor-pointer w-full text-sm text-slate-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-violet-50 file:text-violet-700
          hover:file:bg-violet-100"
          aria-label="Upload"
        ></input>
        <button
          onClick={() => handleUpload(savedConclusion, "conclusion.png")}
          className="text-white hover:scale-95 w-1/2 bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800"
        >
          Upload
        </button>
      </div>
    </div>
  );
};

export default Image;
