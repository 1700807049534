import { FaInstagram } from "react-icons/fa";
import logo from "../assets/Logo-AMIToken.png";

const background = `${process.env.REACT_APP_URL}/conclusion.png`;

const Conclusion = ({ conclusion }) => {
  const { title, content } = conclusion;
  return (
    <>
      <div
        name="conclusion"
        className="w-full h-full lg:h-screen py-4 bg-gradient-to-b from-black via-slate-900 to-black text-white flex flex-col md:flex-row gap-2 border-b-white border-b-2"
      >
        <div className="p-6 md:p-4 mx-auto flex flex-wrap justify-center gap-4 lg:gap-0 items-center w-full">
          <div className="lg:w-1/2 lg:h-3/4">
            <span className="flex justify-center items-center my-8">
              <h1 className="text-3xl lg:text-4xl font-bold inline border-b-4 border-gray-500">
                {title}
              </h1>
            </span>
            <p className="text-sm text-center md:text-xl md:text-left mt-5">
              {content}
            </p>
          </div>
          <div className="lg:w-1/2">
            <img
              src={background}
              alt="conclusion"
              className="lg:w-3/4 lg:h-3/4 rounded-md m-auto"
            />
          </div>
        </div>
      </div>
      <div className="w-full h-full justify-between py-4 px-4 bg-gradient-to-b from-black via-slate-900 to-black text-white flex flex-row border-b-white border-b-2">
        <div className="flex w-1/3 flex-col justify-center ">
          <p className="text-xs md:text-md">
            Copyright©2022 Future School Token
          </p>
          <p className="text-xs md:text-md">All Right Reserved</p>
        </div>
        <div className="flex flex-col w-1/3 items-center">
          <img src={logo} alt="erc20-token" className="w-20 h-18" />
        </div>
        <div className="flex flex-col px-10 w-1/3 justify-center items-end">
          <a
            href="https://www.instagram.com/future.school.official/"
            target="_blank"
            rel="norefferer"
          >
            <FaInstagram className="w-10 h-full" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Conclusion;
