import React from "react";

const background = `${process.env.REACT_APP_URL}/project.png`;

const Portfolio = ({ title, content }) => {
  return (
    <div
      name="project"
      className="w-full h-full bg-gradient-to-b from-black via-slate-900 to-black text-white"
    >
      <div className="p-4 flex flex-wrap justify-evenly items-center">
        <div className="lg:w-1/2">
          <h1 className="text-3xl text-center font-bold">{title}</h1>
          <p className="text-sm text-center md:text-xl md:text-left my-3">
            {content}
          </p>
        </div>
        <div className="w-3/4 mx-auto lg:w-1/2 p-5">
          <img
            src={background}
            alt="procts-token"
            className="lg:w-1/2 lg:h-1/2 rounded-md mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
