import { useEffect, useState } from "react";
import axios from "axios";
import About from "./components/About";
import Conclusion from "./components/Conclusion";
import Experience from "./components/Experience";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Portfolio from "./components/Portfolio";
import Presale from "./components/Presale";
import Roadmap from "./components/Roadmap";
import Youtube from "./components/Youtube";

const initial = {
  home: Object.create(null),
  background: Object.create(null),
  project: Object.create(null),
  token: Object.create(null),
  presale: {
    contractAddress: null,
    walletAddress: null,
    price: [],
    tutorial: [],
  },
  conclusion: Object.create(null),
};

function App() {
  const [data, setData] = useState(initial);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const headersList = {
      Accept: "*/*",
    };

    const reqOptions = {
      url: `${process.env.REACT_APP_URL}/getdata`,
      method: "GET",
      headers: headersList,
    };

    const response = await axios.request(reqOptions);
    setData(response.data);
  }

  return (
    <div>
      <NavBar />
      <Home home={data?.home} />
      <About background={data?.background} />
      <Portfolio
        title={data?.project?.title}
        content={data?.project?.content}
      />
      <Youtube
        demo={data?.project?.demo}
        yt1={data?.project?.youtube1}
        yt2={data?.project?.youtube2}
      />
      <Experience token={data?.token} />
      <Roadmap />
      <Presale presale={data.presale} />
      <Conclusion conclusion={data.conclusion} />
    </div>
  );
}

export default App;
