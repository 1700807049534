import React from "react";

const backgroundImage = `${process.env.REACT_APP_URL}/background.png`;

const About = ({ background }) => {
  return (
    <div
      name="background"
      className="w-full h-full py-4 items-center bg-gradient-to-b from-black via-slate-900 to-black text-white flex flex-col"
    >
      <div className="px-4 flex lg:h-screen flex-wrap justify-between items-center w-full">
        <div className="lg:w-1/2">
          <h1 className="text-xl text-center md:text-3xl font-bold ">
            {background?.title}
          </h1>
          <p className="text-sm text-center md:text-xl md:text-left my-5">
            {background?.content}
          </p>
        </div>
        <div className="w-3/4 mx-auto lg:w-1/2 p-5">
          <img
            src={backgroundImage}
            className="lg:w-1/2 lg:h-1/2 rounded-md mx-auto"
            alt="token-background"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
