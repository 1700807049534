import axios from "axios";
import { useEffect, useState } from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";

import Roadcontent from "./Roadcontent";

const Roadmap = () => {
  const [load, setLoad] = useState([]);

  useEffect(() => {
    fetchLoad();
  }, []);

  const fetchLoad = async () => {
    const fetching = await axios.get(`${process.env.REACT_APP_URL}/roadmap`);
    setLoad(fetching.data);
  };

  return (
    <>
      <div
        name="roadmap"
        className="pt-20 bg-gradient-to-b from-black via-slate-900 to-black w-full h-full p-10 flex flex-col gap-10"
      >
        <h3 className="text-white font-bold text-4xl text-center">ROADMAP</h3>
        <VerticalTimeline>
          {load.map((road, i) => (
            <Roadcontent
              key={i}
              date={road.date}
              token={road.token}
              school={road.school}
            />
          ))}
        </VerticalTimeline>
      </div>
    </>
  );
};

export default Roadmap;
