import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-scroll";

const Home = ({ home }) => {
  return (
    <div
      name="home"
      className="h-screen w-full bg-gradient-to-bl from-purple-900 via-slate-900 to-black"
    >
      <div
        className={`bg-[url("https://fst.sumoboker.repl.co/hero.svg")] bg-no-repeat bg-[length:150%] lg:bg-cover w-full items-center mx-auto flex flex-col h-full md:flex-row`}
      >
        <div className="flex flex-col justify-center p-4 lg:p-12 rounded-lg overflow-hidden my-auto">
          <h3 className="text-green-400 font-semibold my-3">
            {home?.subTittle}
          </h3>
          <h2 className="text-3xl sm:text-4xl font-bold text-white">
            {home?.title}
          </h2>
          <p className="text-white py-4 max-w-md">{home?.content}</p>
          <div className="flex gap-4">
            <a
              href="https://github.com/sumoboke/projects-consul/raw/main/fst-token-whitepaper.pdf"
              target="_blank"
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
            >
              Whitepaper
              <span className="group-hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </a>
            <Link
              to="roadmap"
              smooth
              duration={500}
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
            >
              Roadmap
              <span className="group-hover:rotate-90 duration-300">
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
