import { useEffect, useState } from "react";
import { HiPlus, HiMinus } from "react-icons/hi";
import Loading from "../Loading";
import axios from "axios";

const url = process.env.REACT_APP_URL;

const titleElement = [
  "Home",
  "Background",
  "Project",
  "FST Token",
  "Presale",
  "Conclusion",
];

const LabelText = ({ _label, _value, _content, _setContent, index, name }) => {
  const handleChange = (index) => (e) => {
    let newArr = [..._content];
    for (let key in newArr[index]) {
      if (key == name) {
        if (e.target.value.length < 1) {
          newArr[index][key] = " ";
        } else {
          newArr[index][key] = e.target.value;
        }
      }
    }
    _setContent(newArr);
  };
  return (
    <>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-200">
        {_label}
      </label>
      <textarea
        type="text"
        onChange={handleChange(index)}
        value={_value}
        className="bg-gray-50 border mb-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      ></textarea>
    </>
  );
};

const Edit = () => {
  const [fetchObject, setObject] = useState({});
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);

  const press = async () => {
    const arr = [];
    const obj = {};
    for (const p in fetchObject) {
      arr.push(p);
    }
    arr.forEach((elem, i) => {
      obj[elem] = content[i];
    });

    try {
      const response = await fetch(`${url}/api/data`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const data = await response.json();
      alert(JSON.stringify(data));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      loadContent();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const findTitle = (_index) => {
    return titleElement[_index];
  };

  const loadContent = async () => {
    const data = await axios.get(`${url}/getdata`);
    setObject(data.data);
    const loadData = Object.keys(data.data).map((val) => data.data[val]);
    setContent(loadData);
  };

  return (
    <div className="p-4 h-full">
      {loading && <Loading />}
      <div className="w-full flex mt-5">
        <button
          onClick={() => press()}
          className="text-white mx-auto w-1/2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Save
        </button>
      </div>
      {content.map((isi, i) => (
        <div key={i} className="my-2">
          <h1 className="my-5 font-bold text-lg lg:text-2xl border-b-2 border-gray-300">
            {findTitle(i)}
          </h1>
          {isi.title && (
            <LabelText
              _label={"Title"}
              _value={isi.title}
              _content={content}
              _setContent={setContent}
              index={i}
              name="title"
            />
          )}
          {isi.content && (
            <LabelText
              _label={"Content"}
              _value={isi.content}
              _content={content}
              _setContent={setContent}
              index={i}
              name="content"
            />
          )}
          {isi.subTittle && (
            <LabelText
              _label={"Sub-Title"}
              _value={isi.subTittle}
              _content={content}
              _setContent={setContent}
              index={i}
              name="subTittle"
            />
          )}
          {isi.demo && (
            <LabelText
              _label={"Demo Button"}
              _value={isi.demo}
              _content={content}
              _setContent={setContent}
              index={i}
              name="demo"
            />
          )}
          {isi.youtube1 && (
            <LabelText
              _label={"Shortlink Youtube 1"}
              _value={isi.youtube1}
              _content={content}
              _setContent={setContent}
              index={i}
              name="youtube1"
            />
          )}
          {isi.youtube2 && (
            <LabelText
              _label={"Shortlink Youtube 2"}
              _value={isi.youtube2}
              _content={content}
              _setContent={setContent}
              index={i}
              name="youtube2"
            />
          )}
          {isi.subContent1 && (
            <LabelText
              _label={"Content Project 1"}
              _value={isi.subContent1}
              _content={content}
              _setContent={setContent}
              index={i}
              name="subContent1"
            />
          )}
          {isi.subContent2 && (
            <LabelText
              _label={"Content Project 2"}
              _value={isi.subContent2}
              _content={content}
              _setContent={setContent}
              index={i}
              name="subContent2"
            />
          )}
          {isi.subContent3 && (
            <LabelText
              _label={"Content Project 3"}
              _value={isi.subContent3}
              _content={content}
              _setContent={setContent}
              index={i}
              name="subContent3"
            />
          )}
          {isi.totalIssued && (
            <LabelText
              _label={"Total Issued"}
              _value={isi.totalIssued}
              _content={content}
              _setContent={setContent}
              index={i}
              name="totalIssued"
            />
          )}
          {isi.preSaleIssued && (
            <LabelText
              _label={"Total Presale Issued"}
              _value={isi.preSaleIssued}
              _content={content}
              _setContent={setContent}
              index={i}
              name="preSaleIssued"
            />
          )}
          {isi.contractAddress && (
            <LabelText
              _label={"Smart Contract Address"}
              _value={isi.contractAddress}
              _content={content}
              _setContent={setContent}
              index={i}
              name="contractAddress"
            />
          )}
          {isi.walletAddress && (
            <LabelText
              _label={"Presale Wallet Address"}
              _value={isi.walletAddress}
              _content={content}
              _setContent={setContent}
              index={i}
              name="walletAddress"
            />
          )}
          {isi.price && (
            <div>
              <p>Price</p>
              {isi.price.map((elem, a) => (
                <li key={a} className="list-none">
                  <div className="flex justify-around items-center">
                    <input
                      className="bg-gray-50 border my-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="text"
                      value={elem}
                      onChange={(e) => {
                        let newArr = [...content];
                        for (let k in newArr[i]) {
                          if (k === "price") {
                            newArr[i][k][a] = e.target.value;
                          }
                        }
                        setContent(newArr);
                      }}
                    />
                    {a === isi.price.length - 1 && (
                      <div className="h-full p-2">
                        <button
                          onClick={() => {
                            let newArr = [...content];
                            for (let k in newArr[i]) {
                              if (k === "price") {
                                newArr[i][k].pop();
                              }
                            }
                            setContent(newArr);
                          }}
                          className="text-white w-1/4 h-3/4 bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xl sm:w-auto px-5 py-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        >
                          <HiMinus />
                        </button>
                      </div>
                    )}
                  </div>
                </li>
              ))}
              <div className="w-full justify-center p-2 items-center flex">
                <button
                  onClick={() => {
                    let newArr = [...content];
                    for (let k in newArr[i]) {
                      if (k === "price") {
                        newArr[i][k].push("");
                      }
                    }
                    setContent(newArr);
                  }}
                  className="text-white w-1/4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xl sm:w-auto px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <HiPlus />
                </button>
              </div>
            </div>
          )}
          {isi.tutorial && (
            <div>
              <p>Tutorial</p>
              {isi.tutorial.map((elem, a) => (
                <li key={a} className="list-none">
                  <div className="flex justify-around items-center">
                    <input
                      className="bg-gray-50 border my-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      type="text"
                      value={elem}
                      onChange={(e) => {
                        let newArr = [...content];
                        for (let k in newArr[i]) {
                          if (k === "tutorial") {
                            newArr[i][k][a] = e.target.value;
                          }
                        }
                        setContent(newArr);
                      }}
                    />
                    {a === isi.tutorial.length - 1 && (
                      <div className="h-full p-2">
                        <button
                          onClick={() => {
                            let newArr = [...content];
                            for (let k in newArr[i]) {
                              if (k === "tutorial") {
                                newArr[i][k].pop();
                              }
                            }
                            setContent(newArr);
                          }}
                          className="text-white w-1/4 h-3/4 bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xl sm:w-auto px-5 py-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        >
                          <HiMinus />
                        </button>
                      </div>
                    )}
                  </div>
                </li>
              ))}
              <div className="w-full justify-center p-2 items-center flex">
                <button
                  onClick={() => {
                    let newArr = [...content];
                    for (let k in newArr[i]) {
                      if (k === "tutorial") {
                        newArr[i][k].push("");
                      }
                    }
                    setContent(newArr);
                  }}
                  className="text-white w-1/4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xl sm:w-auto px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <HiPlus />
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Edit;
