import React from "react";

const Youtube = ({ demo, yt1, yt2 }) => {
  return (
    <div
      name="portfolio"
      className="w-full h-full p-6 bg-gradient-to-b from-black via-slate-900 to-black text-white flex flex-col gap-3 justify-center items-center"
    >
      <button className="text-white active:scale-95 w-fit px-16 py-4 mb-28 flex items-center rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer bg-indigo-500 shadow-lg shadow-indigo-500/50 p-2">
        Demo
      </button>
      <div
        className="flex flex-col w-full justify-center px-3
       md:flex-row gap-8"
      >
        <div className="relative overflow-hidden w-full pt-[56.25%]">
          <iframe
            className="absolute top-0 left-0 bottom-0 right-0 w-full h-full  md:h-1/2"
            src="https://www.youtube.com/embed/h-jidzZMY6g"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="relative overflow-hidden w-full pt-[56.25%]">
          <iframe
            className="absolute top-0 left-0 bottom-0 right-0 w-full h-full md:h-1/2"
            src="https://www.youtube.com/embed/j-b27ZfC8Ik"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Youtube;
