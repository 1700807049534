import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const Roadcontent = ({ date, token, school }) => {
  return (
    <VerticalTimelineElement
      contentStyle={{
        background: "rgba(255, 0, 0, 0)",
        color: "#fff",
        borderRadius: "12px",
        border: "solid",
        borderTopColor: "#2d71b5",
        borderLeftColor: "#0384cf",
        borderRightColor: "#0384cf",
        borderBottomColor: "rgba(255, 0, 0, 0)",
      }}
      iconStyle={{ background: "#fff" }}
    >
      <h3 className="font-semibold text-2xl text-center mb-3 text-indigo-500">
        {date}
      </h3>
      {token.length > 0 && (
        <div className="mb-4">
          <h3 className="mb-1">FST Token :</h3>
          <ul className="list-outside list-disc pl-3">
            {token.map((list, i) => (
              <li key={i}>{list}</li>
            ))}
          </ul>
        </div>
      )}
      <h3 className="mb-1">Future School Program :</h3>
      <ul className="list-outside list-disc pl-3">
        {school.map((elem, i) => (
          <li key={i}>{elem}</li>
        ))}
      </ul>
    </VerticalTimelineElement>
  );
};

export default Roadcontent;
