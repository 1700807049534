import { useState } from "react";
import Edit from "./edit/Edit";
import Roadmap from "./edit/Roadmap";
import Image from "./edit/Image";

const elem = [
  {
    title: "Content",
    isi: <Edit />,
  },
  {
    title: "Roadmap",
    isi: <Roadmap />,
  },
  {
    title: "Image",
    isi: <Image />,
  },
];
const Admin = () => {
  const [active, setActive] = useState(elem[0].isi);

  const handleActive = (index) => setActive(elem[index].isi);
  return (
    <div className="w-full h-full bg-inherit">
      <ul className="flex flex-wrap justify-around text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
        {elem.map((isi, i) => (
          <li onClick={() => handleActive(i)} key={i} className="mr-1">
            <a
              href="#"
              className="inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
            >
              {isi.title}
            </a>
          </li>
        ))}
      </ul>
      {active}
    </div>
  );
};

export default Admin;
