import { useState } from "react";
import { BiLinkExternal, BiX } from "react-icons/bi";
import { SiBinance } from "react-icons/si";

const Presale = ({ presale }) => {
  const [copyContract, setCopyContract] = useState(false);
  const [copyWallet, setCopyWallet] = useState(false);

  const { contractAddress, walletAddress, price, tutorial } = presale;
  const handleCopyContract = () => {
    if (copyWallet === true) {
      setCopyWallet(false);
    }
    setCopyContract(true);
    navigator.clipboard.writeText(contractAddress);
  };
  const handleCopyWallet = () => {
    if (copyContract === true) {
      setCopyContract(false);
    }
    setCopyWallet(true);
    navigator.clipboard.writeText(walletAddress);
  };

  return (
    <div
      name="presale"
      className="w-full h-full bg-gradient-to-b py-8 from-black via-slate-900 to-black text-white flex flex-col gap-2"
    >
      <h1 className="text-2xl md:text-3xl font-bold text-center">
        PRESALE TOKEN
      </h1>
      <div className="flex flex-wrap w-full justify-around gap-3 p-6">
        <div className="flex flex-col p-4 items-center gap-4 shadow-md shadow-teal-700 rounded-md">
          <span className="flex items-center justify-center gap-2">
            <SiBinance className="text-lg lg:text-xl" />
            <h1 className="text-xl md:text-2xl font-bold text-center">
              Smart Contract Address
            </h1>
          </span>
          <a
            href={`https://www.bscscan.com/address/${contractAddress}`}
            target="_blank"
            rel="norefferer"
          >
            <span className="flex items-center justify-center md:gap-2">
              <h2 className="text-sm md:text-xl font-semibold text-center">
                {contractAddress}
              </h2>
              <BiLinkExternal className="text-lg md:text-xl hidden lg:inline " />
            </span>
          </a>
          <button
            type="button"
            className="text-white w-1/4 bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 active:scale-95 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={handleCopyContract}
          >
            Copy
          </button>
        </div>
        <div className="flex flex-col p-4 items-center gap-3 shadow-md shadow-teal-700 rounded-md">
          <h1 className="text-xl md:text-2xl font-bold text-center">
            Presale Wallet Address
          </h1>
          <h2 className="text-sm md:text-xl font-semibold text-center">
            {walletAddress}
          </h2>
          <button
            type="button"
            className="text-white w-1/4 bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 active:scale-95 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={handleCopyWallet}
          >
            Copy
          </button>
        </div>
      </div>
      <div className="w-3/4 mx-auto flex flex-wrap p-6 justify-evenly gap-4">
        <div className="flex flex-col shadow-md shadow-teal-700 rounded-lg w-full md:w-1/3 p-4 items-center">
          <h1 className="font-bold text-center text-xl mb-2">Price</h1>
          {price.map((pr, i) => (
            <li key={i}>{pr}</li>
          ))}
        </div>
        <div className="flex flex-col shadow-md shadow-teal-700 rounded-lg w-full md:w-1/3 p-4 gap-1">
          <h1 className="font-bold text-center text-xl mb-2">Tutorial</h1>
          <ol className="list-decimal pl-4 list-outside">
            {tutorial.map((tut, i) => (
              <li key={i}>{tut}</li>
            ))}
          </ol>
        </div>
      </div>
      <div className="bottom-[5%] right-4 fixed">
        <div
          className={`${
            copyContract === false ? "hidden" : "flex"
          } items-center justify-center bg-blue-400 border-l-4 border-blue-700 py-2 px-3 shadow-md mb-2 toast`}
        >
          <div className="text-blue-500 rounded-full bg-white mr-3">
            <svg
              width="1.8em"
              height="1.8em"
              viewBox="0 0 16 16"
              className="bi bi-info"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
              <circle cx="8" cy="4.5" r="1" />
            </svg>
          </div>
          <div className="text-white max-w-xs mr-2">
            Copied Contract Address to Clipboard
          </div>
          <BiX
            className="cursor-pointer h-full w-6"
            onClick={() => setCopyContract(false)}
          />
        </div>
        <div
          className={`${
            copyWallet === false ? "hidden" : "flex"
          } items-center justify-center bg-blue-400 border-l-4 border-blue-700 py-2 px-3 shadow-md mb-2 toast`}
        >
          <div className="text-blue-500 rounded-full bg-white mr-3">
            <svg
              width="1.8em"
              height="1.8em"
              viewBox="0 0 16 16"
              className="bi bi-info"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
              <circle cx="8" cy="4.5" r="1" />
            </svg>
          </div>
          <div className="text-white max-w-xs mr-2">
            Copied Presale Address to Clipboard
          </div>
          <BiX
            className="cursor-pointer h-full w-6"
            onClick={() => setCopyWallet(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default Presale;
