import { useState, useEffect } from "react";
import axios from "axios";

import { HiPlus, HiMinus } from "react-icons/hi";

const url = process.env.REACT_APP_URL;

const Roadmap = () => {
  const [load, setLoad] = useState([]);

  useEffect(() => {
    fetchLoad();
  }, []);

  const fetchLoad = async () => {
    const fetching = await axios.get(`${url}/roadmap`);
    setLoad(fetching.data);
  };

  const check = async () => {
    try {
      const resp = await fetch(`${url}/api/roadmap`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(load),
      });

      const data = await resp.json();
      alert(JSON.stringify(data));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="p-4 w-full items-center">
      <div className="w-full flex">
        <button
          onClick={() => check()}
          className="text-white mx-auto w-1/2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Save
        </button>
      </div>
      {load.map((element, i) => (
        <div
          key={i}
          className="p-4 mx-2 my-4 shadow-md rounded-lg shadow-teal-400"
        >
          <label className="block mb-2 text-lg font-bold text-gray-900 dark:text-gray-200">
            Date
          </label>
          <input
            type="text"
            className="bg-gray-50 border mb-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={(e) => {
              element.date = e.target.value;
              setLoad([...load]);
            }}
            value={element.date}
          />

          <label className="block mb-2 text-md font-medium text-gray-900 dark:text-gray-200">
            FST Token
          </label>
          {element.token.map((tokens, k) => (
            <li key={k} className="list-none my-3">
              <div className="flex justify-around items-center h-full">
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => {
                    let newArr = [...load];
                    newArr[i].token[k] = e.target.value;
                    setLoad(newArr);
                  }}
                  value={tokens}
                />
                {k === element.token.length - 1 && (
                  <div className="h-full p-2">
                    <button
                      onClick={() => {
                        let newArr = [...load];
                        newArr[i].token.pop();

                        setLoad(newArr);
                      }}
                      className="text-white flex justify-center items-center align-middle bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xl sm:w-auto px-5 py-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                    >
                      <HiMinus />
                    </button>
                  </div>
                )}
              </div>
            </li>
          ))}
          <div className="w-full justify-center p-2 items-center flex">
            <button
              onClick={() => {
                let newArr = [...load];
                newArr[i].token.push("");
                setLoad(newArr);
              }}
              className="text-white w-1/4 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xl sm:w-auto px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <HiPlus />
            </button>
          </div>
          <label className="block mb-2 text-md font-medium text-gray-900 dark:text-gray-200">
            Future School Program
          </label>
          {element.school.map((sch, j) => (
            <li key={j} className="list-none my-3">
              <div className="flex justify-around items-center h-full">
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => {
                    let newArr = [...load];
                    newArr[i].school[j] = e.target.value;
                    setLoad(newArr);
                  }}
                  value={sch}
                />
                {j === element.school.length - 1 && (
                  <div className="h-full p-2">
                    <button
                      onClick={() => {
                        let newArr = [...load];

                        newArr[i].school.pop();

                        setLoad(newArr);
                      }}
                      className="text-white flex justify-center items-center align-middle bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xl sm:w-auto px-5 py-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                    >
                      <HiMinus />
                    </button>
                  </div>
                )}
              </div>
            </li>
          ))}
          <div className="w-full justify-center p-2 items-center flex">
            <button
              onClick={() => {
                let newArr = [...load];
                newArr[i].school.push("");
                setLoad(newArr);
              }}
              className="text-white flex justify-center items-center align-middle bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xl sm:w-auto px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <HiPlus />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Roadmap;
