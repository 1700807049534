import React from "react";
import { ReactComponent as Review } from "../assets/Icon-Reviews.svg";
import { ReactComponent as Secure } from "../assets/Icon-Secure.svg";
import { ReactComponent as Decent } from "../assets/Icon-Decentralized.svg";

const Experience = ({ token }) => {
  const {
    title,
    content,
    subContent1,
    subContent2,
    subContent3,
    totalIssued,
    preSaleIssued,
  } = token;
  return (
    <div
      name="token"
      className=" p-6 bg-gradient-to-b from-black via-slate-900 to-black w-full h-full"
    >
      <div className="mx-auto p-2 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-bold border-b-4 border-gray-500 p-2">
            {title}
          </p>
          <p className="py-6 px-2">{content}</p>
        </div>

        <div className="w-full grid grid-cols-2 gap-8 text-center py-8  sm:px-0">
          <div className="flex flex-row gap-4">
            <Review className="h-30 w-[22rem]" />
            <p className="text-left text-xs md:text-lg">{subContent1}</p>
          </div>
          <div className="flex flex-row gap-4">
            <Secure className="h-30 w-[18rem]" />
            <p className="text-left text-xs md:text-lg">{subContent2}</p>
          </div>
          <div className="flex flex-row gap-4">
            <Decent className="h-30 w-[8rem]" />
            <p className="text-left text-xs md:text-lg">{subContent3}</p>
          </div>
        </div>
        <div className="flex flex-col gap-3 md:flex-row justify-center lg:justify-evenly w-full items-center my-20">
          <div className="border-x-4 border-t-2 border-blue-400 py-2 px-6 w-3/4 lg:w-1/2 rounded-lg">
            <h2 className="text-indigo-400 text-2xl text-center">
              FST Token Total Issued
            </h2>
            <p className="text-center text-xl">IDR {totalIssued}</p>
          </div>
          <div className="border-x-4 border-t-2 border-emerald-400 w-3/4 py-2 px-6 lg:w-1/2 rounded-lg">
            <h2 className="text-lime-400 text-2xl text-center">
              FST Token Issued Pre-Sale
            </h2>
            <p className="text-center text-xl">IDR {preSaleIssued}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
